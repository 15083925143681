import React from "react";
import { Container, Navbar } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function SuperUserNavbar() {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    localStorage.removeItem("token");

    toast.success("Logout successful!", {
      autoClose: 1000,
      theme: "colored",
    });

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  return (
    <>
      <Navbar bg="dark" data-bs-theme="dark" className="px-2  fixed_navbar">
        <Container fluid>
          <Navbar.Brand>Dashboard</Navbar.Brand>
          <button
            className="mybtn rounded mt-2 mt-lg-0 ms-lg-0 ms-2 mx-2"
            onClick={handleLogoutClick}
          >
            Logout
          </button>
        </Container>
      </Navbar>
    </>
  );
}

export default SuperUserNavbar;
