import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../../Component/Loader/CustomLoader";
import { BASE_URL } from "../../../Utils/constants";
import "./SKUDetails.css";

function SKUDetails() {
  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 2000, theme: "colored", type: type });
  const navigate = useNavigate();

  const [skuData, setSKUData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setsearch] = useState("");

  //url
  const getSKUDetails_url = BASE_URL + "api/getsku/";

  const getSKUDetails = async () => {
    setLoader(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getSKUDetails_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          setSKUData(res.data?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          notify("Unathorized user", "error");
          navigate("/");
        }
      });
  };

  const filterData = skuData.filter(
    (itm) =>
      itm.skuID.toLowerCase().includes(search.toLowerCase()) ||
      itm.product_name.toLowerCase().includes(search.toLowerCase()) ||
      itm.weight.toString().includes(search)
  );

  useEffect(() => {
    getSKUDetails();
  }, []);

  return (
    <>
      <div className="row mt-2">
        <div className="d-flex justify-content-end align-items-center px-2">
          <div className="col-3">
            <div class="input-group input-group-sm">
              <input
                className="form-control  input-group-sm"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {loader ? (
        <CustomLoader />
      ) : (
        <div className="p-2 table-container">
          <div className="card card_main">
            <div className="card-body">
              <div
                className="table-responsive overflow-auto"
                style={{ maxHeight: "500px" }}
              >
                <table className="table table-bordered">
                  <thead className="sticky-top bg-light">
                    <tr>
                      <th className="table_head text-dark">SKU ID</th>
                      <th className="table_head text-dark">Product Name</th>
                      <th className="table_head text-dark">Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData?.length ? (
                      filterData.map((item) => (
                        <tr
                          className="table_row"
                          key={item.skuID + item.product_name}
                        >
                          <td className="table_data">{item.skuID}</td>
                          <td className="table_data">{item.product_name}</td>
                          <td className="table_data">{item.weight}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-danger text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SKUDetails;
