import React from "react";
import { Outlet } from "react-router-dom";
import CustomNavbar from "./CustomNavbar";
import ManagerTabs from "./ManagerTabs";

function UserComp() {
  // const token = localStorage.getItem("token");
  // const manager_token = localStorage.getItem("managertoken");
  return (
    <>
      {/* {!token && manager_token && (
        <div>
          <CustomNavbar />
          <Outlet />
        </div>
      )} */}
      <div>
        <CustomNavbar />
        {/* <ManagerTabs /> */}
        <Outlet />
      </div>
    </>
  );
}

export default UserComp;
