import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import PieChart from "../../../Component/PieChart/PieChart";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Mybutton from "../../../Component/Mybutton/Mybutton";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../Utils/constants";
import LineChart from "../../../Component/LineChart/LineChart";
import StackedBarChart from "../../../Component/StackedBarChart/StackedBarChart";
import { Bar } from "react-chartjs-2";
import ScatterChart from "../../../Component/Scatter/ScatterChart";

function Graphs() {
  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 2000, theme: "colored", type: type });

  const navigate = useNavigate();

  // States
  const [machineProductionData, setMachineProductionData] = useState({
    productionKG: [],
    machineNames: [],
  });
  const [skuProductionData, setSkuProductionData] = useState({
    productionKG: [],
    skuNames: [],
  });
  const [graphColors] = useState([
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ]);
  const [filterDate, setFilterDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [machineTarget, setMachineTarget] = useState([]);
  const [uniqueDataSet, setUniqueDataSet] = useState(null);

  const [stackedBarData, setstackedBarData] = useState(null);

  // URLs
  const machineTargetUrl = BASE_URL + "/api/machinetarget";
  const getMachineProduction_url = BASE_URL + "api/machine-productionkg/";
  const getSKUProduction_url = BASE_URL + "api/sku-productionkg/";
  const getStackedBar_url = BASE_URL + "api/machinegrap";

  const fetchStackedBar = async () => {
    try {
      const response = await axios.get(getStackedBar_url, {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      });

      if (response?.status === 200) {
        setstackedBarData(response?.data?.data);
      }
    } catch (error) {
      console.error("error fetching data stacked", error);
    }
  };

  useEffect(() => {
    fetchStackedBar();
  }, []);

  // Get machine production data
  const handleMachineProduction = async (startDate, endDate) => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    try {
      const res = await axios.get(getMachineProduction_url, {
        headers: header,
        params: {
          start_date: startDate || "",
          end_date: endDate || "",
        },
      });
      if (res.data.status) {
        const machineNames = res.data.data.map((item) => item.machine_name);
        const productionKGs = res.data.data.map(
          (item) => item.production_kg_sum
        );
        setMachineProductionData({
          machineNames,
          productionKG: productionKGs,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        notify("Unauthorized user", "error");
        navigate("/");
      }
    }
  };

  // Get SKU production data
  const handleSKUProduction = async (startDate, endDate) => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    try {
      const res = await axios.get(getSKUProduction_url, {
        headers: header,
        params: {
          start_date: startDate || "",
          end_date: endDate || "",
        },
      });
      if (res.data.status) {
        const skuNames = res.data.data.map((item) => item.product_name);
        const productionKGs = res.data.data.map(
          (item) => item.production_kg_sum
        );
        setSkuProductionData({
          skuNames,
          productionKG: productionKGs,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        notify("Unauthorized user", "error");
        navigate("/");
      }
    }
  };

  const handleReset = () => {
    setFilterDate({
      startDate: "",
      endDate: "",
    });
    handleMachineProduction();
    handleSKUProduction();
  };

  useEffect(() => {
    handleMachineProduction();
    handleSKUProduction();
  }, []);

  return (
    <>
      <div className="row text-center">
        <div className="col-12">
          <div
            className="row"
            style={{
              overflow: "auto",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div className="col-lg-4 p-2">
              <FloatingLabel controlId="floatingInput" label="Start Date">
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  value={filterDate.startDate}
                  onChange={(e) => {
                    setFilterDate((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }));
                  }}
                />
              </FloatingLabel>
            </div>
            <div className="col-lg-4 p-2">
              <FloatingLabel controlId="floatingInput" label="End Date">
                <Form.Control
                  value={filterDate.endDate}
                  onChange={(e) => {
                    setFilterDate((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }));
                  }}
                  type="date"
                  placeholder="End Date"
                />
              </FloatingLabel>
            </div>
            <div
              className="d-flex justify-content-center gap-2 p-2"
              // style={{
              //   gap: "10px",
              //   padding: "10px",
              //   display: "flex",
              //   justifyContent: "center",
              // }}
            >
              <Mybutton
                // backgroundColor={"blue"}
                color={"#fff"}
                name={"Search"}
                handleClick={() => {
                  if (
                    filterDate.startDate.length &&
                    filterDate.endDate.length
                  ) {
                    handleMachineProduction(
                      filterDate.startDate,
                      filterDate.endDate
                    );
                    handleSKUProduction(
                      filterDate.startDate,
                      filterDate.endDate
                    );
                  }
                }}
              />
              <Mybutton
                backgroundColor={"#fb5e5e"}
                color={"#fff"}
                name={"Reset"}
                handleClick={handleReset}
              />
            </div>
          </div>
        </div>
      </div>

      {/* pie chart data */}
      <div className="row gap-1 p-2">
        <div className="col-1"></div>
        <div className="col-lg-5 border rounded shadow-sm  mb-2">
          <div className="m-2">
            <strong className="">Production KG against Machines</strong>
          </div>
          <PieChart
            heading={"Production KG against Machines"}
            productionKGData={machineProductionData.productionKG}
            labels={machineProductionData.machineNames}
            labelName={"Production KG"}
            colors={graphColors}
            legendLabel="Machine Name"
          />
        </div>
        <div className="col-lg-5 border rounded shadow-sm  mb-2">
          <div className="m-2 ">
            <strong>Production KG against SKU</strong>
          </div>
          <PieChart
            heading={"Production KG against SKU"}
            productionKGData={skuProductionData.productionKG}
            labels={skuProductionData.skuNames}
            labelName={"Production KG"}
            colors={graphColors}
            legendLabel="SKU Name"
          />
        </div>
        <div className="col-1"></div>
      </div>
      {/* end pie */}

      <div className="row ">
        <div className="col-1"></div>
        <div className="col-lg-10 ">
          {stackedBarData !== null ? (
            <ScatterChart data={stackedBarData} />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
}

export default Graphs;
