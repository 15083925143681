import React from "react";
import "./Mybutton.css";

function Mybutton({ name, backgroundColor, color, handleClick }) {
  const customStyle = {
    // backgroundColor: "#7b7bff",
    backgroundColor: backgroundColor,
    color: color,
  };
  return (
    <button
      style={customStyle}
      onClick={handleClick}
      className=" mybtn rounded mt-2 mt-lg-0 ms-lg-0 ms-2"
    >
      {name}
    </button>
  );
}

export default Mybutton;
