import React from "react";
import Navbar from "../../Component/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import Tabs from "../../Component/Tabs/Tabs";

function Dashboard() {
  // const token = localStorage.getItem("token");
  // const manager_token = localStorage.getItem("managertoken");
  return (
    <>
      {/* {token && !manager_token && (
        <div>
          <Navbar />
          <Tabs />
          <Outlet />
        </div>
      )} */}
      <Navbar />
      <Tabs />
      <Outlet />
    </>
  );
}

export default Dashboard;
