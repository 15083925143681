import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Graphs from "../../Pages/HeroSections/Graphs/Graphs";

function PieChart({
  labels,
  labelName,
  productionKGData,
  colors,
  heading,
  legendLabel,
}) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: labels,
    datasets: [
      {
        label: labelName,
        data: productionKGData,
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false, // Hides the legend
      },
    },
  };

  // Extract labels and colors for display
  const legendItems = labels.map((label, index) => (
    <div key={index} className="d-flex align-items-center mb-1">
      <div
        className="legend-color"
        style={{
          backgroundColor: colors[index % colors.length],
          width: "20px",
          height: "20px",
          marginRight: "8px",
          borderRadius: "50%",
        }}
      ></div>
      <div>{label}</div>
    </div>
  ));

  return (
    // <div className="row justify-content-lg-center align-items-lg-center">
    //   <div className="col-lg-6 col-md-8 col-sm-10">
    //     <div className="  p-3">
    //       <div className="d-flex justify-content-center align-items-center ">
    //         <Doughnut data={data} options={options} />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="col-lg-4 col-md-8 col-sm-10 mt-4 mt-lg-0">
    //     <div className="  p-3">
    //       <label className="my-2 fw-bold">{legendLabel}</label>
    //       {legendItems}
    //     </div>
    //   </div>
    // </div>
    <div className="row justify-content-lg-center align-items-lg-center">
      <div className="col-lg-6 col-md-8 col-sm-10">
        <div className="p-3">
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* Machine Name Block */}
            <div className="mb-3">
              {/* <h5 className="text-center">{machineName}</h5> */}
            </div>
            {/* Doughnut Chart */}
            <Doughnut data={data} options={options} />
            {/* Production KG against Machines */}
          </div>
        </div>
      </div>
      {/* <div
        className="mt-3"
        style={{ border: "1px solid red", alignItems: "left" }}
      >
        <label className="my-2 fw-bold">{legendLabel}</label>
        {legendItems}
      </div> */}
      <div
        className="mt-3"
        style={{
          padding: "15px",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <label
          className="my-2 fw-bold"
          style={{
            marginRight: "8px",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {legendLabel}
        </label>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {legendItems}
        </div>
      </div>
    </div>
  );
}

export default PieChart;
