import React from "react";

function PageNotFound() {
  return (
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="text-center">
        <h1 class="display-1 fw-bold" style={{ fontSize: "150px" }}>
          404
        </h1>
        <p class="fs-1">
          {" "}
          <span class="text-danger">Opps!</span> Page not found.
        </p>
        <p class="lead" style={{ fontSize: "1.35rem" }}>
          The page you’re looking for doesn’t exist.
        </p>
      </div>
    </div>
  );
}

export default PageNotFound;
