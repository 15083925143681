// LineChart.js
// import React from "react";
// import PropTypes from "prop-types";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// } from 'chart.js';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const LineChart = ({ data, labels }) => {
//   console.log("LineChart Data:", data);
//   console.log("LineChart Labels:", labels);

//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         label: "Target",
//         data: data,
//         fill: false,
//         borderColor: "rgb(75, 192, 192)",
//         tension: 0.1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     scales: {
//       x: {
//         type: 'category',
//         title: {
//           display: true,
//           text: "Machine Name",
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: "Target",
//         },
//       },
//     },
//   };

//     return <div className="border m-2 p-2 rounded shadow-sm">
//         <label htmlFor="" ></label>
//       <Line data={chartData} options={options} />
//   </div>;
// };

// LineChart.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.number).isRequired,
//   labels: PropTypes.arrayOf(PropTypes.string).isRequired,
// };

// export default LineChart;

import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data }) => {
  // console.log(data);
  return <>{data && <Line data={data} />}</>;
};

export default LineChart;
