import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import toast, { Toaster } from "react-hot-toast";
import Mybutton from "../../../Component/Mybutton/Mybutton";
import axios from "axios";
import { BASE_URL } from "../../../Utils/constants";
import * as XLSX from "xlsx";
import "./componentpo.css";
import CustomLoader from "../../../Component/Loader/CustomLoader";

function ComponentPo() {
  const [compoId, setcompoId] = useState([]);
  const [selcted_compItem, setSelectedCompItem] = useState("");
  const [selcted_compValue, setSelect_compValue] = useState("");
  const [tableData, settableData] = useState([]);
  const [search, setsearch] = useState("");
  const [loader, setLoader] = useState(true);

  //Pagination Codes
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);

  //urls
  const getComponent_url = BASE_URL + "billmaterial/ComponentAPIView";
  const postComponent_url = BASE_URL + "billmaterial/componenetePO/";
  const getComponentTable_url = BASE_URL + "/billmaterial/componenetePO/";
  const exportall_url = BASE_URL + "/billmaterial/ComponentPOListAPIView/";

  const [filterDates, setfilterDates] = useState({
    startDate: "",
    endDate: "",
  });

  // for select option kg,gm
  const unitOptions = [
    { value: "gm", label: "gm" },
    { value: "kg", label: "kg" },
    { value: "nos", label: "nos" },
    { value: "mm", label: "mm" },
    { value: "m", label: "m" },
  ];

  const handleSelect = (selct_item) => {
    setSelectedCompItem(selct_item.item);
    setSelect_compValue(selct_item.item_code);
  };

  //state management for form fields
  const [componentformData, setcomponentformData] = useState({
    new_ponumber: "",
    new_podate: "",
    new_taxinvoice: "",
    new_receivedquantity: "",
    new_locate: "",
    new_poquantity: "",
    new_rates: "",
    new_units: "",
  });

  const handleUnitChange = (e) => {
    const { value } = e.target;
    setcomponentformData((prevData) => ({
      ...prevData,
      new_units: value,
    }));
  };

  // post data
  const handleSubmit = async () => {
    if (
      !selcted_compValue ||
      !componentformData.new_ponumber ||
      !componentformData.new_podate ||
      !componentformData.new_taxinvoice ||
      !componentformData.new_receivedquantity ||
      !componentformData.new_locate ||
      !componentformData.new_poquantity ||
      !componentformData.new_rates ||
      !componentformData.new_units
    ) {
      toast.error("All fields are required");
      return;
    }

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const data = new FormData();
    const receivedQuantity = parseInt(componentformData.new_receivedquantity);
    const rate = parseInt(componentformData.new_rates);
    const basic_rate = receivedQuantity * rate;
    const total_gst = basic_rate * 0.18;
    const total_amount = basic_rate + total_gst;
    data.append("newpo_number", componentformData.new_ponumber);
    data.append("newpo_date", componentformData.new_podate);
    data.append("newtaxinvoiceNo", componentformData.new_taxinvoice);
    data.append("newreceived_quantity", componentformData.new_receivedquantity);
    data.append("newlocation", componentformData.new_locate);
    data.append("newpo_quantity", componentformData.new_poquantity);
    data.append("newrate", componentformData.new_rates);
    data.append("newunit", componentformData.new_units);
    data.append("newcomponent", selcted_compValue);
    // total calculatiopn gst
    data.append("basic_rate", basic_rate.toFixed(3));
    data.append("total_gst", total_gst.toFixed(3));
    data.append("total_ammount", total_amount.toFixed(3));

    await axios
      .post(postComponent_url, data, {
        headers: header,
      })
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          toast.success(res.data.message);
          setcomponentformData({
            new_ponumber: "",
            new_podate: "",
            new_taxinvoice: "",
            new_receivedquantity: "",
            // new_taxinvoicequantity: "",
            new_locate: "",
            new_poquantity: "",
            new_rates: "",
            new_units: "",
          });
          setSelectedCompItem("");
          setSelect_compValue("");
          fetchTableData();
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error("Unathorized user", "error");
        }
      });
  };

  const handleSearch = () => {
    if (filterDates.startDate && filterDates.endDate) {
      fetchTableData(filterDates.startDate, filterDates.endDate);
    }
  };

  // for dropdown
  const fetchData = async () => {
    await axios
      .get(getComponent_url)
      .then((res) => {
        if (res.data.status) {
          setcompoId(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTableData = async (startDate, endDate) => {
    setLoader(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getComponentTable_url, {
        headers: header,
        params: {
          from_date: startDate || "",
          to_date: endDate || "",
          page: currentPage,
          page_size: pageSize,
        },
      })
      .then((res) => {
        if (res.data.status) {
          // settableData(res.data.data);
          const { data, pagination } = res.data;
          settableData(data);

          // newly added data for pagination

          if (pagination && pagination.length > 0) {
            const totalItems = pagination[0].total;
            const calculatedTotalPages = Math.ceil(totalItems / pageSize);
            setTotalPages(calculatedTotalPages);
          }

          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  // for filter table data as per the po_date
  const filteredAndSortedData = tableData
    .filter((item) => {
      const itemDate = new Date(item.newpo_date);
      const filterStartDate = new Date(filterDates.startDate);
      const filterEndDate = new Date(filterDates.endDate);

      return (
        (!filterDates.startDate || itemDate >= filterStartDate) &&
        (!filterDates.endDate || itemDate <= filterEndDate)
      );
    })
    .sort((a, b) => new Date(b.newpo_date) - new Date(a.newpo_date));

  // console.log(tableData);
  // console.log(filteredAndSortedData);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setcomponentformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const downloadExcel = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.get(exportall_url, {
        headers: header,
        params: {},
      });

      const tableData = response.data.data;

      if (!Array.isArray(tableData)) {
        throw new Error("Expected an array for tableData");
      }

      const dataToExport = tableData.map((item) => ({
        "PO Number": item.newpo_number,
        "Created Date": item.newcreated_date,
        "PO Date": item.newpo_date,
        "Tax Invoice No": item.newtaxinvoiceNo,
        "Received Quantity": item.newreceived_quantity,
        Location: item.newlocation,
        "PO Quantity": item.newpo_quantity,
        Unit: item.newunit,
        Component: item.newcomponent,
        Rate: item.newrate,
        "Basic Rate": item.basic_rate,
        "Total GST (18%)": item.total_gst,
        "Total Amount": item.total_ammount,
      }));

      // Calculate totals
      const totals = tableData.reduce(
        (acc, item) => {
          acc.newreceived_quantity +=
            parseFloat(item.newreceived_quantity) || 0;
          acc.newrate += parseFloat(item.newrate) || 0;
          acc.basic_rate += parseFloat(item.basic_rate) || 0;
          acc.total_gst += parseFloat(item.total_gst) || 0;
          acc.total_amount += parseFloat(item.total_ammount) || 0;
          return acc;
        },
        {
          newreceived_quantity: 0,
          newrate: 0,
          basic_rate: 0,
          total_gst: 0,
          total_amount: 0,
        }
      );

      // Add totals row
      dataToExport.push({
        "PO Number": "Total",
        "Created Date": "",
        "PO Date": "",
        "Tax Invoice No": "",
        "Received Quantity": "",
        Location: "",
        "PO Quantity": "",
        Unit: "",
        Component: "",
        Rate: totals.newrate,
        "Basic Rate": totals.basic_rate,
        "Total GST (18%)": totals.total_gst,
        "Total Amount": totals.total_amount,
      });

      // Convert data to worksheet and save as Excel file
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "ComponentData");

      XLSX.writeFile(workbook, "ComponentData.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Calculate totals
  const totals = tableData.reduce(
    (acc, item) => {
      acc.newreceived_quantity += parseFloat(item.newreceived_quantity) || 0;
      // acc.newtaxinvoice_quantity +=
      //   parseFloat(item.newtaxinvoice_quantity) || 0;
      acc.newrate += parseFloat(item.newrate) || 0;
      acc.basic_rate += parseFloat(item.basic_rate) || 0;
      acc.total_gst += parseFloat(item.total_gst) || 0;
      acc.total_amount += parseFloat(item.total_ammount) || 0;
      return acc;
    },
    {
      newreceived_quantity: 0,
      // newtaxinvoice_quantity: 0,
      newrate: 0,
      basic_rate: 0,
      total_gst: 0,
      total_amount: 0,
    }
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [currentPage, filterDates]);

  const filterdata = filteredAndSortedData.filter(
    (itm) =>
      itm.newpo_number?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newcreated_date?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newpo_date?.toString().includes(search) ||
      itm.newtaxinvoiceNo?.toString().includes(search) ||
      itm.newreceived_quantity?.toString().includes(search) ||
      itm.newlocation?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newpo_quantity?.toString().includes(search) ||
      itm.newunit?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newcomponent?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newrate.toString().includes(search) ||
      itm.basic_rate.toString().includes(search) ||
      itm.total_gst.toString().includes(search) ||
      itm.total_ammount.toString().includes(search)
  );

  return (
    <>
      <div>
        <Toaster />

        {loader ? (
          <CustomLoader />
        ) : (
          <>
            <div className="row p-2 m-3 main_class">
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <InputGroup>
                  <DropdownButton variant="outline-secondary" title="component">
                    {compoId.length
                      ? compoId.map((item) => {
                          return (
                            <Dropdown.Item
                              key={item?.item_code}
                              onClick={() => handleSelect(item)}
                            >
                              {item?.item}
                            </Dropdown.Item>
                          );
                        })
                      : ""}
                  </DropdownButton>
                  <Form.Control
                    readOnly
                    aria-label="Text input with dropdown button"
                    value={
                      selcted_compItem ? selcted_compItem : "Select CompItem"
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%" }}
                  >
                    PO Number
                  </span>
                  <input
                    value={componentformData.new_ponumber}
                    onChange={handleInputChange}
                    name="new_ponumber"
                    type="text"
                    placeholder="ponumber"
                    className="form-control"
                    aria-label="newponumber"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <InputGroup>
                  <InputGroup.Text style={{ minWidth: "35%" }}>
                    PO Date
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="new_podate"
                    value={componentformData.new_podate}
                    onChange={handleInputChange}
                    placeholder="YYYY-MM-DD"
                    aria-label="Created Quotation Date"
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%" }}
                  >
                    Tax Invoice No.
                  </span>
                  <input
                    name="new_taxinvoice"
                    type="number"
                    placeholder="taxinvoice"
                    className="form-control"
                    aria-label="newtaxinvoiceNo"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%" }}
                    value={componentformData.new_taxinvoice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%" }}
                  >
                    Received Qty
                  </span>
                  <input
                    name="new_receivedquantity"
                    type="number"
                    placeholder="received_quantity"
                    className="form-control"
                    aria-label="newreceived_quantity"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%" }}
                    value={componentformData.new_receivedquantity}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%" }}
                  >
                    Location
                  </span>
                  <input
                    name="new_locate"
                    type="text"
                    placeholder="location"
                    className="form-control"
                    aria-label="newlocation"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%" }}
                    value={componentformData.new_locate}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%" }}
                  >
                    PO Qty
                  </span>
                  <input
                    name="new_poquantity"
                    type="number"
                    placeholder="po_quantity"
                    className="form-control"
                    aria-label="newpo_quantity"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%" }}
                    value={componentformData.new_poquantity}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%" }}
                  >
                    Rate
                  </span>
                  <input
                    name="new_rates"
                    type="number"
                    placeholder="rate"
                    className="form-control"
                    aria-label="newrate"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%" }}
                    value={componentformData.new_rates}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%" }}
                  >
                    Unit
                  </span>
                  <Form.Select
                    aria-label="Default select example"
                    value={componentformData.new_units}
                    onChange={handleUnitChange}
                  >
                    <option>Select Unit</option>
                    {unitOptions.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div className="text-center p-2 mt-2">
                <Mybutton name="Submit" handleClick={handleSubmit} />
              </div>
            </div>

            <div className="p-2 m-3 main_class">
              {/*export all  */}
              <div className="d-lg-flex align-items-center justify-content-center">
                <div className="col-lg-6 d-flex justify-content-lg-end">
                  <h4 className="text-center px-2 justify-content-center align-items-center">
                    {/* Consumable Data List */}
                  </h4>
                </div>
                <div className="col-lg-6 d-lg-flex justify-content-lg-end justify-content-center gap-2">
                  <div className="text-center me-2">
                    <Mybutton
                      name={"Export All"}
                      color={"#fff"}
                      // backgroundColor={"#7b7bff"}
                      handleClick={downloadExcel}
                    />
                  </div>
                </div>
              </div>
              {/* search & reset button */}
              <div className="my-3 mx-2 p-2">
                <li className="my-2 ms-2 text-dark">Filter By Date</li>
                <div className="d-lg-flex justify-content-start align-items-end gap-2 flex-wrap">
                  <div>
                    <h6
                      className="text-secondary mb-2"
                      style={{ fontSize: "13px" }}
                    >
                      From Date
                    </h6>
                    <input
                      onChange={(e) => {
                        setfilterDates((prev) => ({
                          ...prev,
                          startDate: e.target.value,
                        }));
                      }}
                      value={filterDates.startDate}
                      type="date"
                      placeholder="YYYY-MM-DD"
                      className="form-control productiondate"
                    />
                  </div>
                  <div>
                    <h6
                      className="text-secondary mb-2 my-2"
                      style={{ fontSize: "13px" }}
                    >
                      To Date
                    </h6>
                    <input
                      onChange={(e) => {
                        setfilterDates((prev) => ({
                          ...prev,
                          endDate: e.target.value,
                        }));
                      }}
                      value={filterDates.endDate}
                      type="date"
                      placeholder="YYYY-MM-DD"
                      className="form-control productiondate"
                    />
                  </div>

                  <div className="d-flex text-center gap-1 justify-content-center mt-2">
                    <div className="">
                      <Mybutton
                        name={"Search"}
                        style={{ backgroundColor: "#7b7bff" }}
                        handleClick={handleSearch}
                      />
                    </div>

                    <div className="">
                      <Mybutton
                        backgroundColor={"#fb5e5e"}
                        // style={{ backgroundColor: "#7b7bff" }}
                        name={"Reset Filter"}
                        handleClick={() => {
                          fetchTableData();
                          setfilterDates({
                            startDate: "",
                            endDate: "",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* search */}
            <div className="row mt-2">
              <div className="d-flex justify-content-end align-items-center px-2">
                <div className="col-3">
                  <div className="input-group input-group-sm">
                    <input
                      className="form-control  input-group-sm"
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className=" p-2 table-container">
              <div className="card card_main">
                <div className="card-body">
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    {filterdata.length > 0 ? (
                      <>
                        <table className="table table-bordered ">
                          <thead className="sticky-top bg-light">
                            <tr>
                              <th className="table_head text-dark">
                                PO Number
                              </th>
                              <th className="table_head text-dark">
                                Created Date
                              </th>
                              <th className="table_head text-dark">PO Date</th>
                              <th className="table_head text-dark">
                                Tax Invoice No
                              </th>
                              <th className="table_head text-dark">
                                Received Quantity
                              </th>
                              <th className="table_head text-dark">Location</th>
                              <th className="table_head text-dark">
                                PO Quantity
                              </th>
                              <th className="table_head text-dark">Unit</th>
                              <th className="table_head text-dark">
                                Component
                              </th>
                              <th className="table_head text-dark">Rate</th>
                              <th className="table_head text-dark">
                                Basic Rate
                              </th>
                              <th className="table_head text-dark">
                                Total GST(18%)
                              </th>
                              <th className="table_head text-dark">
                                Total Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterdata.map((item) => (
                              <tr className="table_row" key={item.id}>
                                <td>{item.newpo_number}</td>
                                <td>{item.newcreated_date}</td>
                                <td>{item.newpo_date}</td>
                                <td>{item.newtaxinvoiceNo}</td>
                                <td>{item.newreceived_quantity}</td>
                                {/* <td>{item.newtaxinvoice_quantity}</td> */}
                                <td>{item.newlocation}</td>
                                <td>{item.newpo_quantity}</td>
                                <td>{item.newunit}</td>
                                <td>{item.newcomponent}</td>
                                <td>{item.newrate}</td>
                                <td>{item.basic_rate}</td>
                                <td>{item.total_gst}</td>
                                <td>{item.total_ammount}</td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={3} className="text-right fw-bold">
                                Total:
                              </td>
                              <td></td>
                              <td className="fw-bold">
                                {totals.newreceived_quantity.toFixed(3)}
                              </td>

                              <td></td>
                              <td></td>
                              <td colSpan={2}></td>
                              <td className="fw-bold">
                                {totals.newrate.toFixed(3)}
                              </td>
                              <td className="fw-bold">
                                {totals.basic_rate.toFixed(3)}
                              </td>
                              <td className="fw-bold">
                                {totals.total_gst.toFixed(3)}
                              </td>
                              <td className="fw-bold">
                                {totals.total_amount.toFixed(3)}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {totalPages > 0 ? (
                          <Pagination className="justify-content-center">
                            <Pagination.First
                              onClick={() => handlePageChange(1)}
                              disabled={currentPage === 1}
                            />
                            <Pagination.Prev
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            />
                            {[...Array(totalPages)].map((_, index) => (
                              <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            />
                            <Pagination.Last
                              onClick={() => handlePageChange(totalPages)}
                              disabled={currentPage === totalPages}
                            />
                          </Pagination>
                        ) : (
                          <div className="text-center text-danger">
                            <p>No Pages Found</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="d-flex justify-content-center ">
                        <p className="text-danger fw-bold">No Data Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ComponentPo;
