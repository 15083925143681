import React, { useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import "react-toastify/dist/ReactToastify.css";
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    localStorage.clear();

    toast.success("Logout successful!", {
      autoClose: 1000,
      theme: "colored",
    });

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar fixed_navbar">
      <h2 className="navbar-title">Dashboard</h2>

      <div className="navbar-logout">
        <button
          className="mybtn rounded mt-2 mt-lg-0 ms-lg-0 ms-2 mx-2"
          type="button"
          onClick={handleLogoutClick}
        >
          Logout
        </button>
      </div>

      {isMobileMenuOpen && (
        <div className="mobile-menu">
          <button
            type="button"
            className="btn btn-dark logout-btn btn-sm"
            onClick={handleLogoutClick}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default Navbar;
