import React from "react";
import SuperUserNavbar from "./SuperUserNavbar";
import SuperUserTabs from "./SuperUserTabs";
import { Outlet } from "react-router-dom";

function SuperAdmin() {
  return (
    <div>
      <SuperUserNavbar />
      <SuperUserTabs />
      <Outlet />
    </div>
  );
}

export default SuperAdmin;
